import { SvgIconProps } from "@mui/material/SvgIcon";
import { SvgIcon } from "@mui/material";

export default function FrowningFaceIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="66" height="66" viewBox="0 0 66 66" fill="none" {...props}>
      <rect
        x="0.5"
        y="0.43457"
        width="65"
        height="65"
        rx="32.5"
        fill="currentColor"
      />
      <path
        d="M30.6285 14.5449C31.08 14.4782 31.5356 14.4411 31.9922 14.4341C32.8278 14.4341 32.9416 16.2355 33.1621 18.1171C33.3171 19.4328 33.46 21.5926 33.4673 22.9154C33.4882 26.4051 33.5092 29.8947 33.5302 33.3844C33.5383 33.4453 33.5336 33.5072 33.5166 33.5664C33.4995 33.6255 33.4704 33.6807 33.4309 33.7287C33.38 33.7642 33.322 33.789 33.2608 33.8016C33.1995 33.8141 33.1362 33.8142 33.0749 33.8018C32.2344 33.7475 30.4735 34.2733 30.3984 33.4481C29.7565 26.3343 30.3984 22.2245 29.3811 16.2237C29.3476 16.0328 29.3533 15.8373 29.3981 15.6486C29.4428 15.4599 29.5256 15.2818 29.6416 15.1245C29.7577 14.9672 29.9047 14.8339 30.0741 14.7324C30.2435 14.6309 30.432 14.5631 30.6285 14.5331V14.5449ZM45.1953 19.9704C44.4081 21.2201 42.933 22.1137 41.4361 21.9652C40.1185 21.8355 38.9728 20.9301 38.3164 19.8077C37.66 18.6853 37.333 16.3086 37.333 16.3086C37.333 16.127 37.7109 16.0162 38.2607 15.9384C38.3202 15.9304 38.3808 15.9415 38.4332 15.97C38.4857 15.9985 38.5271 16.0429 38.5513 16.0964C38.6337 16.2685 38.7233 16.4406 38.825 16.6151C39.3773 17.5583 40.3437 18.3246 41.4555 18.4331C42.715 18.558 43.96 17.8059 44.6212 16.7519C44.7472 16.5515 44.8974 16.2968 45.0451 16.0162C45.0932 15.9261 45.166 15.8508 45.2553 15.7983C45.3446 15.7459 45.447 15.7185 45.5513 15.7191H45.9825C47.489 15.7286 45.98 18.7089 45.1953 19.9586V19.9704Z"
        fill="#212121"
      />
      <path
        d="M21.2502 49.1711C20.9702 50.4646 20.9561 51.7961 21.2089 53.0879L21.2119 53.0993C21.2451 53.4083 21.3857 53.6954 21.6105 53.9128C21.8991 54.1382 22.3129 54.1103 22.6831 54.0697C23.5065 53.9792 24.3262 53.8784 25.1422 53.7671C25.3738 53.7358 25.5907 53.6353 25.7632 53.4793C25.9356 53.3234 26.055 53.1198 26.1051 52.8964C26.321 51.9525 26.5575 51.0567 26.7691 50.3678C27.7762 47.1076 30.5381 44.2292 33.9699 43.6636C36.9912 43.1665 40.0927 44.5031 42.2263 46.6166C42.8957 47.2782 43.5068 47.9938 44.0533 48.7561C44.1655 48.9124 44.3268 49.0286 44.5133 49.0872C44.6998 49.1459 44.9015 49.1439 45.0884 49.0817C45.535 48.9327 45.9807 48.7807 46.4257 48.6257C46.6306 48.5564 46.8191 48.4465 46.9792 48.3028C47.1393 48.1591 47.2675 47.9849 47.3556 47.7912C47.4438 47.5974 47.49 47.3884 47.4913 47.1774C47.4926 46.9665 47.449 46.7581 47.3632 46.5657C45.4662 42.4433 41.7335 39.1252 37.2241 38.1023C32.175 36.9556 26.3578 38.9879 23.5215 43.2825C22.3482 45.0615 21.6888 47.1301 21.2502 49.1711Z"
        fill="#212121"
      />
    </SvgIcon>
  );
}
